import { gql } from '@apollo/client';

export const CREATE_USER_TYPE = gql`
  mutation createUserType($projectId: String!, $name: String!, $permission: EMemberPermission!, $approvalRequired: Boolean!) {
    userType {
      createUserType(userType: { projectId: $projectId, name: $name, permission: $permission, approvalRequired: $approvalRequired }) {
        id
        name
        status
        permission
      }
    }
  }
`;
