import { gql } from '@apollo/client';

const SEARCH_USER_TYPES = gql`
  query searchUserTypes($projectId: String, $skipUserCount: Boolean = true) {
    userType {
      searchUserTypes(projectId: $projectId) {
        id
        name
        permission
        status
        userCount @skip(if: $skipUserCount)
        userTypeCode
      }
    }
  }
`;

export { SEARCH_USER_TYPES };
