import React, { useEffect, useState } from 'react';

import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';
import { ButtonIconOnly, FlexContainer, InfoIcon, InformationBox, PlusIcon, Preloader, SearchFilter, Spacing } from '@netfront/ui-library';

import { UserGroupAddContact } from './UserGroupAddContact';
import { UserGroupContactForm } from './UserGroupContactForm';
import { getGroupContactItems } from './UserGroupContactsTab.helpers';
import { UserGroupContactsTabProps, IContact, IGroupUser } from './UserGroupContactsTab.interfaces';

import { SidebarContainer, SidebarList, SidebarListItem } from '../../../../components/Shared';
import { GET_GROUP_CONTACTS } from '../../../../graphql';
import { useToast } from '../../../../hooks';

const UserGroupContactsTab = ({ selectedGroupId }: UserGroupContactsTabProps) => {
  const { handleToastError } = useToast();

  const [filteredGroupContacts, setFilteredGroupContacts] = useState<IContact[]>([]);
  const [groupContacts, setGroupContacts] = useState<IContact[]>([]);
  const [groupContact, setGroupContact] = useState<IContact | undefined>(undefined);
  const [isAddContactVisible, setIsAddContactVisible] = useState<boolean>(false);
  const [isContactFormVisible, setIsContactFormVisible] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const [getGroupContacts, { loading: isGetGroupContactsLoading }] = useLoggedGeladaLazyQuery({
    options: {
      fetchPolicy: 'cache-and-network',
      onCompleted: ({
        groupContact: {
          get: { items },
        },
      }) => {
        setGroupContacts(getGroupContactItems(items as IGroupUser[], selectedGroupId));
        setFilteredGroupContacts(getGroupContactItems(items as IGroupUser[], selectedGroupId));
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    query: GET_GROUP_CONTACTS,
  });

  const handleClickClearSearch = () => {
    setSearchValue('');
    setFilteredGroupContacts(groupContacts);
  };

  const handleClickContact = (contactId: number) => {
    setIsContactFormVisible(!isContactFormVisible);
    setGroupContact(groupContacts.find((item) => item.id === contactId));
  };

  const handleGetGroupContacts = (groupId: number) => {
    void getGroupContacts({
      variables: {
        groupId,
      },
    });
  };

  const handleSearchInputChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(value);
  };

  const handleToggleAddContactVisibility = () => {
    setIsAddContactVisible(!isAddContactVisible);
    handleClickClearSearch();
  };

  const handleToggleContactFormVisibility = () => {
    setIsContactFormVisible(!isContactFormVisible);
    handleClickClearSearch();
  };

  useEffect(() => {
    const filteredSearchList = groupContacts
      .filter(
        ({ firstname, lastname, email }) =>
          firstname.toLocaleLowerCase().trim().includes(searchValue.trim().toLocaleLowerCase()) ||
          lastname.toLocaleLowerCase().trim().includes(searchValue.trim().toLocaleLowerCase()) ||
          email.toLocaleLowerCase().trim().includes(searchValue.trim().toLocaleLowerCase()),
      )
      .sort((a, b) => a.firstname.localeCompare(b.firstname));

    setFilteredGroupContacts(filteredSearchList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (!selectedGroupId) {
      return;
    }

    handleGetGroupContacts(selectedGroupId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupId]);

  return (
    <SidebarContainer>
      <Preloader isLoading={isGetGroupContactsLoading} />

      {isContactFormVisible && (
        <UserGroupContactForm
          contact={groupContact}
          onOpenUpdateContacts={handleToggleContactFormVisibility}
          onUpdateContacts={() => handleGetGroupContacts(selectedGroupId)}
        />
      )}

      {isAddContactVisible && (
        <UserGroupAddContact
          contactGroupId={selectedGroupId}
          onOpenUpdateContacts={handleToggleAddContactVisibility}
          onUpdateContacts={() => handleGetGroupContacts(selectedGroupId)}
        />
      )}

      {!isContactFormVisible && !isAddContactVisible && (
        <main>
          {!filteredGroupContacts.length ? (
            <Spacing>
              <InformationBox icon={InfoIcon}  message="There are no contacts attached to this group" />
            </Spacing>
          ) : (
            <>
              <Spacing>
                <section>
                  <SearchFilter
                    hasSearchButton={false}
                    id="searchContacts"
                    isLabelHidden={true}
                    labelText="Search contacts"
                    name="searchContacts"
                    placeholder="Filter name, email"
                    type="text"
                    value={searchValue}
                    isFullWidth
                    isLabelSideBySide
                    onChange={handleSearchInputChange}
                    onClear={handleClickClearSearch}
                    onSearch={() => {
                      return;
                    }}
                  />
                </section>
              </Spacing>

              <section>
                <SidebarList itemsLength={filteredGroupContacts.length} text="contact" hasCount>
                  {filteredGroupContacts.map(({ id, firstname, lastname }) => (
                    <SidebarListItem
                      key={id}
                      name={`${firstname} ${lastname}`}
                      supportiveText="Update the contact"
                      title={`${firstname} ${lastname}`}
                      isSettingsButton
                      onClick={() => handleClickContact(id)}
                    />
                  ))}
                </SidebarList>
              </section>
            </>
          )}

          <FlexContainer justifyContent="end" tag="section">
            <ButtonIconOnly icon={PlusIcon} text="Add contact" onClick={handleToggleAddContactVisibility} />
          </FlexContainer>
        </main>
      )}
    </SidebarContainer>
  );
};

export { UserGroupContactsTab };
