// TODO: Add the following once status is updatable -    $status: Boolean status: $status

import { gql } from '@apollo/client';

export const UPDATE_USER_TYPE = gql`
  mutation updateUserType(
    $userTypeId: Int!
    $name: String!
    $permission: EMemberPermission!
    $approvalRequired: Boolean!
    $status: EUserTypeStatus!
  ) {
    userType {
      updateUserType(
        userType: { userTypeId: $userTypeId, name: $name, permission: $permission, approvalRequired: $approvalRequired, status: $status }
      ) {
        id
        projectId
        name
        userTypeCode
        permission
        status
      }
    }
  }
`;
