import { ChangeEvent, FormEvent, useEffect, useState, useContext } from 'react';

import { IDropDownListOption } from '@netfront/ekardo-content-library';
import {
  ICustomField,
  IGetCustomFieldsOnCompletedResponse,
  IUser,
  useGetCustomFields,
  useLoggedGeladaLazyQuery,
  useLoggedGeladaMutation,
  useSearchGroups,
} from '@netfront/gelada-identity-library';
import {
  ButtonIconOnly,
  CloseIcon,
  DropzoneFileUpload,
  FlexContainer,
  IOption,
  InputFieldWrapper,
  Label,
  Select,
  SelectWithSearch,
  SidebarButtons,
  Spacing,
  Spinner,
  Textarea,
  ToggleSwitch,
  XlsxIcon,
} from '@netfront/ui-library';
import cx from 'classnames';
import ExcelJS from 'exceljs';
import { useRouter } from 'next/router';
import { IInvitationField, IExcelFileField } from 'types';

import { SidebarContainer } from 'components/Shared';

import { InvitationsBulkInviteTabProps, IBulkInvitesUserType, IBulkUploadUser } from './InvitationsBulkInviteTab.interfaces';
import styles from './InvitationsBulkInviteTab.module.css';

import { CachingEntitiesContext } from '../../../../context';
import { CREATE_MULTIPLE_USER_GROUP_INVITATION_REQUEST, SEARCH_USER_TYPES } from '../../../../graphql';
import { useToast } from '../../../../hooks';
import { IInvite, ISelectWithSearchItemType } from '../../../Pages';
import { USER_MANAGEMENT_PAGE_CONSTANTS } from '../../../Pages/UserManagementPages/UserManagementPages.constants';

const InvitationsBulkInviteTab = ({ hasAllGroupsOption, onClose, onUpdate }: InvitationsBulkInviteTabProps) => {
  const {
    query: { projectId: queryProjectId },
  } = useRouter();

  const { project } = useContext(CachingEntitiesContext);
  const { services } = project ?? {};
  const { handleToastError, handleToastSuccess, handleToastCustomError } = useToast();

  const { defaultServices, socialServices } = USER_MANAGEMENT_PAGE_CONSTANTS;

  const [projectId, setProjectId] = useState<string>('');
  const [invitation, setInvitation] = useState<IInvite>();
  const [isGroupSearchContentVisible, setSearchIsGroupContentVisible] = useState<boolean>(false);
  const [isSocialAccessActive, setIsSocialAccessActive] = useState<boolean>(false);
  const [isUserTypeSearchContentVisible, setIsUserTypeSearchContentVisible] = useState<boolean>(false);
  const [newMembers, setNewMembers] = useState<IBulkUploadUser[]>([]);
  const [userTypes, setUserTypes] = useState<ISelectWithSearchItemType[]>([]);
  const [excelFileFields, setExcelFileFields] = useState<IExcelFileField[]>([]);
  const [groupDropdownListOptions, setGroupsDropdownItems] = useState<IDropDownListOption[]>();
  const [invitationFields, setInvitationFields] = useState<IInvitationField[]>([
    {
      id: 'email',
      name: 'Email',
      type: 'STANDARD',
    },
    {
      id: 'firstName',
      name: 'Firstname',
      type: 'STANDARD',
    },
    {
      id: 'lastName',
      name: 'Lastname',
      type: 'STANDARD',
    },
  ]);

  const onGetCustomFieldsCompleted = (data: IGetCustomFieldsOnCompletedResponse) => {
    const additionalFields = data.customFields.map((customField) => {
      const field: IInvitationField = { id: customField.id, name: customField.name, type: 'CUSTOM_FIELD' };
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return field;
    });

    setInvitationFields([...invitationFields, ...additionalFields]);
  };

  const { handleGetCustomFields } = useGetCustomFields({
    onCompleted: onGetCustomFieldsCompleted,
  });

  const [createBulkGroupInvitation, { loading: isBulkUpdateUsersLoading }] = useLoggedGeladaMutation({
    options: {
      onCompleted: (data) => {
        if (!data) {
          return;
        }

        if (onUpdate) {
          onUpdate();
        }

        handleToastSuccess({
          message: 'Success',
        });

        onClose();
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: CREATE_MULTIPLE_USER_GROUP_INVITATION_REQUEST,
  });

  const [searchUserTypes, { loading: isSearchUserTypesLoading }] = useLoggedGeladaLazyQuery({
    options: {
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ userType: { searchUserTypes: getUserTypes } }) => {
        const selectUserTypes: ISelectWithSearchItemType[] = [...(getUserTypes as IBulkInvitesUserType[])].map(({ id, name }) => ({
          id,
          label: name,
        }));

        setUserTypes(selectUserTypes);
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    query: SEARCH_USER_TYPES,
  });

  const { handleSearchGroups, isLoading: isSearchGroupsLoading = false } = useSearchGroups({
    onCompleted: ({ groups }) => {
      const groupsSelectSearchList = groups.map(
        ({ id, name }): IDropDownListOption => ({
          id: String(id),
          label: name,
          value: String(id),
        }),
      );

      setGroupsDropdownItems(hasAllGroupsOption ? [{
        id: '0',
        label: 'All groups',
        value: '0',
      },
      ...groupsSelectSearchList
      ] :groupsSelectSearchList);

    },
    onError: (error) => {
      handleToastError({
        error,
        hasCloseButton: true,
      });
    },
  });

  const handleCreateBulkInvitations = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (excelFileFields.find((r) => r.mappingId === undefined)) {
      handleToastCustomError({ message: 'All the excel fields must be mapped-out' });
      return;
    }

    const members = [];
    for (const entry of newMembers) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const customFields = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const user: { [key: string]: any } = {};
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      for (const prop in entry as any) {
        const excelField = excelFileFields.find((r) => r.id === prop);

        const field = invitationFields.find((r) => String(r.id) === excelField?.mappingId && r.type == excelField.mappingType);

        if (!field) continue;

        if (field.type === 'STANDARD') {
          user[field.id] = entry[prop] as IUser[keyof IUser];
        } else {
          customFields.push({ value: entry[prop], customFieldId: Number(field.id) });
        }
      }
      user.customFields = customFields as unknown as ICustomField[]; 
      members.push(user as IUser);
    }

    await createBulkGroupInvitation({
      variables: {
        invitation: {
          ...invitation,
          users: members,
        },
      },
    });
  };

  const handleUpdateInput = ({ target: { name, value } }: ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>) => {
    setInvitation(
      (currentState) =>
        ({
          ...currentState,
          [name]: value,
        } as IInvite),
    );
  };

  const handleReadExcel = (input: File) => {
    const reader = new FileReader();
  
    reader.onload = async (event) => {
      const { target } = event;
  
      if (!target?.result) return;
  
      const buffer = target.result as ArrayBuffer;
  
      // Load the file into ExcelJS
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(buffer);
  
      // Assume you're reading the first sheet
      const [sheet] = workbook.worksheets;
  
      // Convert the sheet into a JSON-like structure
      const contentAsObject: IBulkUploadUser[] = [];
      const fields: IExcelFileField[] = [];
  
      // Process each row
      sheet.eachRow((row, rowIndex) => {
        if (rowIndex === 1) return; // Skip header row
        const rowObject: IBulkUploadUser = {};
  
        sheet.getRow(1).eachCell((cell, colIndex) => {
          const header = cell.text.trim();
          const cellValue = row.getCell(colIndex).value;
  
          rowObject[header] = extractCellValue(cellValue);
        });
  
        contentAsObject.push(rowObject);
      });
  
      // Generate fields from the content
      for (const myObject of contentAsObject) {
        for (const prop in myObject) {
          const existingField = fields.find((c) => c.id === prop);
          if (!existingField) {
            const field: IExcelFileField = { id: prop, values: [String(myObject[prop])] };
            fields.push(field);
          } else {
            existingField.values?.push(myObject[prop] as string);
          }
        }
      }
  
      setExcelFileFields(fields);
      setNewMembers(contentAsObject);
    };
  
    reader.readAsArrayBuffer(input);
  }
  
  // Utility function to extract clean values from cells
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function extractCellValue(cellValue: any): string {
    if (typeof cellValue === 'object' && cellValue !== null) {
      // Handle email hyperlinks
      if (cellValue.text?.richText?.[0]?.text) {
        return String(cellValue.text.richText[0].text);
      }
      // Handle hyperlinks
      if (cellValue.hyperlink) {
        return String(cellValue.hyperlink).replace('mailto:', ''); // Extract email part if hyperlink
      }
    }
    return String(cellValue); // Return as is if not a complex object
  }

  const handleClearUsers = () => {
    setNewMembers([]);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFileDrop = (files: File[]) => {
    handleReadExcel(files[0]);
  };

  const handleSocialAccessActive = () => {
    setIsSocialAccessActive(!isSocialAccessActive);

    setInvitation(
      (currentState) =>
        ({
          ...currentState,
          services: !isSocialAccessActive ? socialServices : defaultServices,
        } as IInvite),
    );
  };

  useEffect(() => {
    if (!projectId) return;

    void searchUserTypes({
      variables: {
        projectId,
      },
    });

    void handleGetCustomFields({
      projectId: String(projectId),
      scope: 'USER',
    });

    void handleSearchGroups({
      projectId: String(projectId),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);


  const isLoading = isSearchUserTypesLoading || isBulkUpdateUsersLoading || isSearchGroupsLoading;

  const options: IOption[] = invitationFields.map((r) => {
    const a: IOption = { id: Number(r.id), name: r.name, value: r.id };
    return a;
  });

  return (
    <SidebarContainer>
      <Spinner isLoading={isLoading} />

      <form
        onSubmit={(event) => {
          handleCreateBulkInvitations(event).catch((error) => {
            handleToastError({
              error,
            });
          });
        }}
      >
        <section className="pb-6">
          <InputFieldWrapper id="download-template" label="Download template" isLabelSideBySide>
          <FlexContainer justifyContent="flex-end" isFullWidth>
            <a
                href="https://ekardo-assets-prod.s3.ap-southeast-2.amazonaws.com/templates/user_multiple_invite_template.xlsx"
                id="download-template"
                download
              >
              <XlsxIcon className="w-8 h-8 c-icon"/>
            </a>
          </FlexContainer>
           
          </InputFieldWrapper>
        </section>

        <section className="pb-6">
          {Boolean(newMembers.length) && (
            <div className="flex justify-between items-center">
              <label className="weight-300 text-xs block mb-3" htmlFor="download-template">
                Upload users
              </label>
              <ButtonIconOnly
                additionalClassNames="border-none mr-4"
                icon={CloseIcon}
                text="Remove users"
                onClick={handleClearUsers}
              />
            </div>
          )}
          {Boolean(excelFileFields) && (
            <>
              {excelFileFields.map((r: IExcelFileField) => (
                <Spacing key={r.id}>
                  <Label forId="groups" labelText={`Column name: ${r.id}`} />
                  <Spacing>
                    <div>
                      <ul className={cx(styles['c-invited-user-list-items'])}>
                        {r.values?.map((value, index) => (
                          <li key={`${value}-${String(index)}`} className={cx(styles['c-invited-user-list-item'])} title="Search list item">
                            <div className={cx(styles['c-invited-user-list-item__content'])}>
                              <span className={cx(styles['c-invited-user-list-item__label'])}>{value}</span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Spacing>
                  <Spacing>
                    <Select
                      id={`mapping-field-${r.id}`}
                      labelText="Map user field"
                      name={`mapping-field-${r.id}`}
                      options={options}
                      onChange={({ target: { value } }) => {
                        const invitationField = invitationFields.find((d) => String(d.id) === value);
                        r.mappingId = value;
                        r.mappingType = invitationField?.type;
                      }}
                    />
                  </Spacing>
                </Spacing>
              ))}
            </>
          )}

          {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
          {newMembers.length ? <></> : <DropzoneFileUpload labelText="Upload users" onDrop={(file) => handleFileDrop(file)} />}
        </section>

        <section>
          <SelectWithSearch
            additionalClassNames="c-select-with-search__users-table"
            buttonText="All groups"
            id="id_select_groups_bulk_search"
            isDisplaySearchContent={isGroupSearchContentVisible}
            labelText="Groups"
            searchList={groupDropdownListOptions ?? []}
            isAvatarVisible
            isLabelSideBySide
            isRequired
            onDisplaySearchContent={() => setSearchIsGroupContentVisible(!isGroupSearchContentVisible)}
            onSearchItemClick={(id: string | number) =>
              setInvitation(
                (currentState) =>
                  ({
                    ...currentState,
                    groupId: Number(id),
                  } as unknown as IInvite),
              )
            }
          />

          <SelectWithSearch
            additionalClassNames="c-select-with-search__users-table" 
            buttonText="All user types" 
            id="userTypes" 
            isDisplaySearchContent={isUserTypeSearchContentVisible}
            labelText="User types"
            searchList={userTypes}
            isAvatarVisible
            isLabelSideBySide
            isRequired
            onDisplaySearchContent={() => setIsUserTypeSearchContentVisible(!isUserTypeSearchContentVisible)}
            onSearchItemClick={(id: string | number) =>
              setInvitation(
                (currentState) =>
                  ({
                    ...currentState,
                    userTypeId: id,
                  } as IInvite),
              )
            }
          />
        </section>
        {services?.includes('BONOBO') && (
          <ToggleSwitch
            id="socialAccess"
            isChecked={isSocialAccessActive}
            labelText="Forum | Social access"
            isLabelSideBySide
            onChange={handleSocialAccessActive}
          />
        )}

        <section>
          <Textarea id="custom-message" labelText="Invite message" name="customMessage" isLabelSideBySide onChange={handleUpdateInput} />
        </section>

        <SidebarButtons
          buttonSize="xs"
          isSaveButtonDisabled={Boolean(!(invitation?.groupId && invitation.userTypeId))}
          submitButtonText="Invite"
          onCancel={onClose}
          onSaveButtonType="submit"
        />

      </form>
    </SidebarContainer>
  );
};

export { InvitationsBulkInviteTab };
