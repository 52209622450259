/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { FormFieldProps } from "@netfront/ui-library";
import { ICustomFieldItem } from "hooks";
import { generateRandomId } from "utils";


const getCustomFieldType = ({multiResponseType, type}: { multiResponseType?: string; type?: string;})  => {
  if (type === 'CustomFieldNumberGraphType') return 'number';
  if (type === 'CustomFieldTextGraphType') return 'text';
  if (type === 'CustomFieldDateGraphType') return 'date';
  if (type === 'CustomFieldBooleanGraphType') return 'boolean';
  if (type === 'CustomFieldMultiResponseGraphType' && multiResponseType === 'RADIO') return 'radio';
  if (type === 'CustomFieldMultiResponseGraphType' && multiResponseType === 'DROP_DOWN_LIST') return 'dropdown';
  if (type === 'CustomFieldMultiResponseGraphType' && multiResponseType === 'CHECKBOX') return 'checkbox';
  
  return '';
}

const getCustomFieldDefaultValues = ({ customField }: { customField?: ICustomFieldItem}): FormFieldProps => {
  const { 
    name,
    description,
    isRequired,
    scope,
    type: multiResponseType,
    __typename: type,
    minNumber,
    maxNumber,
    rangeFrom,
    rangeTo,
    availableOptions = []
  } = customField ?? {};

  return {
    name: name || '',
    description: description || '',
    isRequired: isRequired || false,
    scope: scope || '',
    type: getCustomFieldType({ type, multiResponseType }),
    minNumber,
    maxNumber,
    rangeFrom,
    rangeTo,
    availableOptions: availableOptions.map((availableOption) => (
      {
        tempId: generateRandomId(),
        ...availableOption
      }
    )),
  };

};



export { getCustomFieldDefaultValues };