// TODO: Add user status when status field is inserted into updateUserAsAdmin

import React, { useEffect, useState, useContext, ChangeEvent } from 'react';

import { useCookie } from '@netfront/common-library';
import {
  convertDBUser,
  DEFAULT_STORAGE_EXPIRY_OPTIONS,
  IDBUser,
  useDeleteUsers,
  useDomain,
  useGetCustomFields,
  useImpersonateUser,
  useLoggedGeladaMutation,
  useLoginWithImpersonatedToken,
  useUpdateUserAsAdmin,
  useUpdateUserService,
} from '@netfront/gelada-identity-library';
import {
  Avatar,
  Button,
  Dialog,
  EnterIcon,
  Input,
  NavigationButton,
  SidebarButtons,
  SingleDatePicker,
  Spacing,
  Spinner,
  ToggleSwitch,
} from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { SidebarContainer } from 'components/Shared';

import { ICustomField, ICustomFieldResponses, IUpdatedCustomField, UserGeneralTabProps } from './UserGeneralTab.interfaces';

import { CachingEntitiesContext } from '../../../../context';
import { UPDATE_USER_STATUS } from '../../../../graphql';
import { useToast, useAnswerCustomFields, useToggleElevateGroupMember } from '../../../../hooks';
import { USER_MANAGEMENT_PAGE_CONSTANTS } from '../../../Pages/UserManagementPages/UserManagementPages.constants';

const UserGeneralTab = ({ impersonateUserLink, onClose, onDeleted, onUpdated, user }: UserGeneralTabProps) => {
  const {
    createImpersonatedUserAccessTokenCookie,
    createImpersonatedUserRefreshTokenCookie,
    createImpersonatedUserDataCookie,
    createStopImpersonationReturnUrlCookie,
    getProjectDomainCookie,
    isSecureCookie,
  } = useCookie();
  const { getDomain } = useDomain();
  const {
    push,
    query: { projectId: queryProjectId },
  } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const { project, isProduct } = useContext(CachingEntitiesContext);
  const { services } = project ?? {};

  const { defaultServices, socialServices } = USER_MANAGEMENT_PAGE_CONSTANTS;

  const [projectId, setProjectId] = useState<string>('');
  const [currentUser, setCurrentUser] = useState<IDBUser>(user);
  const [customFields, setCustomFields] = useState<IUpdatedCustomField[]>([]);
  const [hasStatusChanged, setHasStatusChanged] = useState<boolean>(false);
  const [isAdditionalDetailsVisible, setAreAdditionalDetailsVisible] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isSocialAccessActive, setIsSocialAccessActive] = useState<boolean>(false);
  const [isUserStatusActive, setIsUserStatusActive] = useState<boolean>(user.status === 'ACTIVE');
  const [isTeamAdmin, setIsTeamAdmin] = useState(user.isProjectAdmin);
  const [isUpdateToAdminDialogOpen, setIsUpdateToAdminDialogOpen] = useState<boolean>(false);

  const [executeUpdateUserStatus, { loading: isActivationUserLoading = false }] = useLoggedGeladaMutation({
    options: {
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: UPDATE_USER_STATUS,
  });


  const { handleToggleElevateGroupMember, isLoading: isToggleElevateGroupMemberLoading = false } = useToggleElevateGroupMember({
    onCompleted: () => {
      onUpdated(false);
      handleToastSuccess({
        message: 'User admin access updated successfully',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const { handleAnswerCustomFields, isLoading: isAnswerCustomFieldsLoading = false } = useAnswerCustomFields({
    onCompleted: () => {
      handleToastSuccess({
        message: 'User custom fields updated successfully',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const { handleDeleteUsers, isLoading: isDeleteUsersLoading = false } = useDeleteUsers({
    onCompleted: () => {
      onDeleted(currentUser.id);
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const { handleGetCustomFields, isLoading: isGetCustomFieldsLoading = false } = useGetCustomFields({
    onCompleted: (data) => {
      const updatedCustomFields: IUpdatedCustomField[] = data.customFields.map((customField) => {
        const { id: customFieldId, __typename, name: customFieldName, responses = [] } = customField as unknown as ICustomField;

        const value =
          responses && responses.length ? responses[0].text ?? responses[0].value ?? responses[0].dateTime ?? responses[0].number : '';
        return {
          customFieldId,
          customFieldName,
          text: value,
          __typename,
        };
      });
      setCustomFields(updatedCustomFields);
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const { handleImpersonateUser, isLoading: isImpersonateUserLoading = false } = useImpersonateUser({
    onCompleted: ({ token }) => {
      if (impersonateUserLink) {
        window.open(`${impersonateUserLink}?t=${token}`);
        return;
      }

      void handleLoginWithImpersonatedToken({
        projectId: String(projectId),
        token,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const { handleLoginWithImpersonatedToken, isLoading: isLoginWithImpersonatedTokenLoading = false } = useLoginWithImpersonatedToken({
    onCompleted: ({ accessToken, refreshToken, user: impersonatedUser }) => {
      const domain = getDomain();
      const isSecure = isSecureCookie(process.env.REACT_APP_COOKIE_ATTRIBUTE_SECURE);

      if (accessToken) {
        createImpersonatedUserAccessTokenCookie({
          optionalCookieAttributesInput: {
            domain,
            secure: isSecure,
            expiryOptions: {
              storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.accessToken,
            }
          },
          value: accessToken,
        });
      }

      if (refreshToken) {
        createImpersonatedUserRefreshTokenCookie({
          optionalCookieAttributesInput: {
            domain,
            secure: isSecure,
            expiryOptions: {
              storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.refreshToken,
            }
          },
          value: refreshToken,
        });
      }

      if (impersonatedUser) {
        const updatedImpersonatedUser = convertDBUser(impersonatedUser);

        const {
          credential: { email },
          firstName,
          id,
          lastName,
        } = updatedImpersonatedUser;

        /*
         * Note
         *  - The whole user object is too large to store in a cookie
         *  - Extract only the important user data to store in a cookie
         */

        createImpersonatedUserDataCookie({
          optionalCookieAttributesInput: {
            domain,
            secure: isSecure,
            expiryOptions: {
              storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.userData,
            }
          },
          value: JSON.stringify({
            email,
            firstName,
            id,
            lastName,
          }),
        });
      }

      createStopImpersonationReturnUrlCookie({
        optionalCookieAttributesInput: {
          domain,
          secure: isSecure,
        },
        value: document.URL,
      });

      const projectDomain = getProjectDomainCookie();

      if (projectDomain) {
        push(`http://${projectDomain}`).catch((error) =>
          handleToastError({
            error,
          }),
        );
      }

      onClose();
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const { handleUpdateUserAsAdmin, isLoading: isUpdateUserAsAdminLoading = false } = useUpdateUserAsAdmin({
    onCompleted: () => {
      onUpdated();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpdateUserService, isLoading: isUpdateUserServiceLoading = false } = useUpdateUserService({
    onCompleted: () => {
      onClose();
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const handleSocialAccessChange = () => {
    setIsSocialAccessActive(!isSocialAccessActive);
  };

  const handleToggleAdditionalDetails = () => {
    setAreAdditionalDetailsVisible(!isAdditionalDetailsVisible);
  };

  const handleUpdateCredentialInput = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentUser((currentState) => ({
      ...currentState,
      credential: {
        ...currentState.credential,
        [name]: value,
      },
    }));
  };

  const handleUpdateUserInput = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentUser((currentState) => ({
      ...currentState,
      [name]: value,
    }));
  };

  const handleUpdateCustomFieldBoolean = ({ target }: ChangeEvent<HTMLInputElement>, customFieldId: number, customFieldName: string) => {
    const { checked: isChecked } = target;
    const updatedFields = customFields.map((customField) => {
      const { customFieldId: currentCustomFieldId } = customField;

      if (customFieldId === currentCustomFieldId) {
        return {
          customFieldId,
          customFieldName,
          text: String(isChecked).charAt(0).toUpperCase() + String(isChecked).slice(1),
          __typename: customField.__typename,
        };
      }

      return customField;
    });

    setCustomFields(updatedFields);
  };
  const handleUpdateCustomFieldDate = (date: Date | null, customFieldId: number, customFieldName: string) => {
    const updatedFields = customFields.map((customField) => {
      const { customFieldId: currentCustomFieldId } = customField;

      if (customFieldId === currentCustomFieldId) {
        return {
          customFieldId,
          customFieldName,
          text: date ? date.toISOString() : '',
          __typename: customField.__typename,
        };
      }

      return customField;
    });

    setCustomFields(updatedFields);
  };

  const handleUpdateCustomField = (
    { target: { value } }: ChangeEvent<HTMLInputElement>,
    customFieldId: number,
    customFieldName: string,
  ) => {
    const updatedFields = customFields.map((customField) => {
      const { customFieldId: currentCustomFieldId } = customField;

      if (customFieldId === currentCustomFieldId) {
        return {
          customFieldId,
          customFieldName,
          text: value.toString(),
          __typename: customField.__typename,
        };
      }

      return customField;
    });

    setCustomFields(updatedFields);
  };

  const handleUpdateUserStatus = () => {
    setIsUserStatusActive(!isUserStatusActive);
    setHasStatusChanged(true);
    setCurrentUser({
      ...user,
      status: isUserStatusActive ? 'DEACTIVATED' : 'ACTIVE',
    });
  };

  const handleUpdateUser = () => {
    if (hasStatusChanged) {
      void executeUpdateUserStatus({
        variables: {
          userIds: [userId],
          projectId,
          newUserStatus: !isUserStatusActive ? 'DEACTIVATED' : 'ACTIVE',
        },
      });
    }

    void handleUpdateUserAsAdmin({
      communityName,
      email,
      firstName,
      lastName,
      phoneNumber,
      userId,
    });
  };

  const toggleElevateUserAsProjectAdmin = () => {
    handleToggleElevateGroupMember({
      projectId: String(projectId),
      userId,
      permission: 'MANAGE_USERS'
    });

    setIsTeamAdmin(true);
  }

  const handleUpdateAdditionalDetails = () => {
    const updatedCustomFields = customFields.map(({ text, customFieldId }) => {
      return {
        customFieldId,
        value: String(text),
      };
    });

    void handleAnswerCustomFields({
      projectId: String(projectId),
      responses: updatedCustomFields as ICustomFieldResponses[],
      userId,
    });

    void handleUpdateUserService({
      services: isSocialAccessActive ? defaultServices : socialServices,
      userIds: [user.id],
    });
  };

  useEffect(() => {
    if (!projectId) {
      return;
    }

    void handleGetCustomFields({
      projectId: String(projectId),
      scope: 'USER',
      userId: user.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, isAdditionalDetailsVisible]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading =
    isActivationUserLoading ||
    isAnswerCustomFieldsLoading ||
    isDeleteUsersLoading ||
    isGetCustomFieldsLoading ||
    isImpersonateUserLoading ||
    isLoginWithImpersonatedTokenLoading ||
    isUpdateUserAsAdminLoading ||
    isUpdateUserServiceLoading ||
    isToggleElevateGroupMemberLoading;

  const {
    communityName,
    credential,
    firstName,
    id: userId,
    lastName,
  } = currentUser;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const { email = '' , phoneNumber = ''} = credential ?? {};

  const fullName = `${firstName} ${lastName}`.trim();

  return (
    <SidebarContainer>
      <Spinner isLoading={isLoading} />

      {isAdditionalDetailsVisible ? (
        <>
          {customFields.length > 0 && (
            <>
              <Spacing size="x-large">
                <NavigationButton
                  additionalClassNames="c-sidebar-navigation-button"
                  direction="previous"
                  icon={EnterIcon}
                  rotationCssSuffix="180"
                  text="Back to general"
                  onClick={handleToggleAdditionalDetails}
                />
              </Spacing>

              <Input
                id="userId"
                labelText="User Id"
                name="userId"
                type="text"
                value={userId}
                isDisabled
                isLabelSideBySide
                onChange={handleUpdateUserInput}
              />

              {customFields.map((customField) => {
                const { customFieldId, customFieldName, text, __typename } = customField;
                return (
                  <section key={`custom-field-${customFieldId}`}>
                    {__typename === 'CustomFieldBooleanGraphType' && (
                      <ToggleSwitch
                        id={`custom-field-${customFieldId}`}
                        isChecked={String(text).toLowerCase() === 'true'}
                        labelText={customFieldName}
                        isLabelSideBySide
                        onChange={(event) => handleUpdateCustomFieldBoolean(event, customFieldId, customFieldName)}
                      />
                    )}
                    {__typename === 'CustomFieldDateGraphType' && (
                      <SingleDatePicker 
                        config={{
                          maxDate: new Date('01-01-2050'),
                          }} 
                          dateInputProps={{
                            id: `custom-field-${customFieldId}`,
                            name: `new_custom-field-${customFieldId}`,
                            type: 'text',
                            labelText: customFieldName,
                          }}
                          selectedDates={text ? [new Date(text)]: undefined} 
                          onSelected={(dates: Date[]) => handleUpdateCustomFieldDate(dates[0], customFieldId, customFieldName)} 
                        />
                    )}
                    {__typename === 'CustomFieldTextGraphType' && (
                      <Input
                        id={`custom-field-text-${customFieldName}`}
                        labelText={customFieldName}
                        name={customFieldName}
                        type="text"
                        value={text}
                        isLabelSideBySide
                        onChange={(event) => handleUpdateCustomField(event, customFieldId, customFieldName)}
                      />
                    )}
                    {__typename === 'CustomFieldNumberGraphType' && (
                      <Input
                        id={`custom-field-number-${customFieldName}`}
                        labelText={customFieldName}
                        name={customFieldName}
                        type="number"
                        value={text}
                        isLabelSideBySide
                        onChange={(event) => handleUpdateCustomField(event, customFieldId, customFieldName)}
                      />
                    )}
                  </section>
                );
              })}

              {services?.includes('BONOBO') && (
                <Spacing size="2x-large">
                  <ToggleSwitch
                    id="socialAccess"
                    isChecked={isSocialAccessActive}
                    labelText="Forum | Social access"
                    isLabelSideBySide
                    onChange={handleSocialAccessChange}
                  />
                </Spacing>
              )}

              <SidebarButtons
                buttonSize="xs"
                onCancel={onClose}
                onSave={() => handleUpdateAdditionalDetails()}
              />
            </>
          )}
        </>
      ) : (
        <>
          <Spacing size="large">
            <section className="flex justify-between items-center">
              <Avatar title={fullName} />

              <ToggleSwitch
                additionalClassNames="c-user-status-toggle"
                id="user-status"
                isChecked={isUserStatusActive}
                labelText="Activated"
                isInline
                onChange={handleUpdateUserStatus}
              />
            </section>
          </Spacing>

          <Input id="first-name" labelText="First name" name="firstName" type="text" value={firstName} isLabelSideBySide onChange={handleUpdateUserInput} />

          <Input id="last-name" labelText="Last name" name="lastName" type="text" value={lastName} isLabelSideBySide onChange={handleUpdateUserInput} />

          <Input id="email" labelText="Email" name="email" type="text" value={email} isLabelSideBySide onChange={handleUpdateCredentialInput} />

          <Input id="phone" labelText="Phone" name="phoneNumber" type="text" value={phoneNumber} isLabelSideBySide onChange={handleUpdateCredentialInput} />

          <Input
            id="display-name"
            labelText="Display name"
            name="communityName"
            tooltipText="Displayed user name"
            type="text"
            value={communityName}
            isLabelSideBySide
            onChange={handleUpdateUserInput}
          />

        {!isProduct && (
          <Spacing>
            <ToggleSwitch
              id="id_team_admin_access"
              isChecked={isTeamAdmin}
              isDisabled={isTeamAdmin}
              labelText="Team admin access"
              tooltipPosition="start"
              tooltipText="This will enable the selected user to access and manage features within the CMS. Only select this if the user is on your project team"
              isLabelSideBySide
              onChange={() => setIsUpdateToAdminDialogOpen(true)}
            />
          </Spacing>
        )}
        {
          !isTeamAdmin && (
            <Dialog
              confirmText="Confirm"
              isOpen={isUpdateToAdminDialogOpen}
              title="Are you sure?"
              onCancel={() => setIsUpdateToAdminDialogOpen(false)}
              onClose={() => setIsUpdateToAdminDialogOpen(false)}
              onConfirm={toggleElevateUserAsProjectAdmin}
            >
              <>
                <p>Updating this user to a project admin will give them full access to the administration area and user information. This operation can not be undone, please confirm to continue.</p>
                
              </>
            </Dialog>

          )
        }
          
          
          <Dialog
            isOpen={isDeleteDialogOpen}
            title={`Delete ${fullName}?`}
            isNarrow
            onCancel={() => setIsDeleteDialogOpen(false)}
            onClose={() => setIsDeleteDialogOpen(false)}
            onConfirm={() => {
              if (!projectId) {
                return;
              }

              void handleDeleteUsers({
                newUserStatus: 'DELETED',
                projectId: String(projectId),
                userIds: [userId],
              });
            }}
          />

          {Boolean(customFields.length) && customFields.length > 0 && (
            <button
              className="bg-transparent p-6 text-center w-full border-none color-green-400 text-base pointer"
              type="button"
              onClick={handleToggleAdditionalDetails}
            >
              Additional details
            </button>
          )}

          <SidebarButtons
            additionalButton={impersonateUserLink ?
              <Button
                size="xs"
                text="Impersonate"
                variant="primary-inverse"
                onClick={() => {
                  void handleImpersonateUser({
                    userId,
                  });
                }}
              /> : null
            }
            buttonSize="xs"
            onCancel={onClose}
            onDelete={() => setIsDeleteDialogOpen(true)}
            onSave={handleUpdateUser}
          />
        </>
      )}
    </SidebarContainer>
  );
};

export { UserGeneralTab };
