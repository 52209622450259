import React, { FormEvent, useState } from 'react';

import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';
import { EnterIcon, Input, NavigationButton, Preloader, SidebarButtons, Spacing } from '@netfront/ui-library';

import { UserGroupAddUnitProps } from './UserGroupAddUnit.interfaces';

import { ADD_UNIT } from '../../../../../graphql';
import { useToast } from '../../../../../hooks';

const UserGroupAddUnit = ({ onOpenUpdateUnits, onUpdateGroupUnits, selectedGroupUnits, unitGroupId }: UserGroupAddUnitProps) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const [unitName, setUnitName] = useState<string>('');

  const [addUnit, { loading: isAddUnitLoading }] = useLoggedGeladaMutation({
    options: {
      onCompleted: ({ unit: { add: unit } }) => {
        /* eslint-disable @typescript-eslint/no-unsafe-argument */
        onUpdateGroupUnits([...selectedGroupUnits, unit]);
        onOpenUpdateUnits();

        handleToastSuccess({
          message: 'Unit added successfully!',
        });
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: ADD_UNIT,
  });

  /* eslint-disable @typescript-eslint/no-floating-promises */
  const handleAddUnit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    addUnit({
      variables: {
        groupId: unitGroupId,
        unitName,
      },
    });
  };

  const handleChangeUnitName = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setUnitName(value);
  };

  return (
    <>
      <Preloader isLoading={isAddUnitLoading} />

      <Spacing size="x-large">
        <NavigationButton
          additionalClassNames="c-sidebar-navigation-button"
          direction="previous"
          icon={EnterIcon}
          rotationCssSuffix="180"
          text="Back to units"
          onClick={onOpenUpdateUnits}
        />
      </Spacing>

      <form onSubmit={handleAddUnit}>
        <Spacing size="x-large">
          <Input id="unitName" labelText="Name" name="name" type="text" value={unitName} isLabelSideBySide isRequired onChange={handleChangeUnitName} />
        </Spacing>

        <SidebarButtons
          buttonSize="xs"
          onCancel={onOpenUpdateUnits}
          onSaveButtonType="submit"
        />
      </form>
    </>
  );
};

export { UserGroupAddUnit };
