import { useEffect, useState, ChangeEvent, useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { IDropDownListOption } from '@netfront/ekardo-content-library';
import {
  DBMembershipPermissionType,
  IDBInvitation,
  useGetCustomFields,
  useGetProduct,
  useLoggedGeladaMutation,
  useSearchGroups,
  useSearchUserTypes,
} from '@netfront/gelada-identity-library';
import {
  Dialog,
  Input,
  Textarea,
  SelectWithSearch,
  Spacing,
  Spinner,
  ToggleSwitch,
  PasswordInput,
  SidebarButtons,
  RadioButtonGroup,
  useControlledForm,
  ControlledForm,
  FormFieldProps,
  SingleDatePicker,
} from '@netfront/ui-library';
import isEmpty from 'lodash.isempty';
import { useRouter } from 'next/router';
import { Control, Controller, ControllerFieldState } from 'react-hook-form';
import * as yup from 'yup';

import { SidebarContainer } from 'components/Shared';

import { IBulkInvitesUserType } from '../InvitationsBulkInviteTab';

import { PERMISSIONS_ITEMS } from './InvitationsGeneralTab.constants';
import { InvitationsGeneralTabProps } from './InvitationsGeneralTab.interfaces';

import { CachingEntitiesContext } from '../../../../context';
import { CREATE_PROJECT_INVITATION } from '../../../../graphql';
import {
  IInvitationCustomField,
  useCreateGroupInvitation,
  useCreateUser,
  useDeleteInvitation,
  useGetCustomFieldInvitationAnswers,
  useResendInvitation,
  useToast,
} from '../../../../hooks';
import { ISelectWithSearchItemType } from '../../../Pages';
import { USER_MANAGEMENT_PAGE_CONSTANTS } from '../../../Pages/UserManagementPages/UserManagementPages.constants';
import { ICustomField, IUpdatedCustomField } from '../../UserManagement/UserGeneralTab';

const InvitationsGeneralTab = ({
  hasAllGroupsOption = false,
  hasGroups = true,
  onClose,
  onDeleted,
  onUpdated,
  selectedInvitation,
}: InvitationsGeneralTabProps) => {
  const { getProduct } = useGetProduct();
  const {
    query: { projectId: queryProjectId },
  } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const { project, isProduct } = useContext(CachingEntitiesContext);
  const { services } = project ?? {};

  const { defaultServices, socialServices } = USER_MANAGEMENT_PAGE_CONSTANTS;

  const [allUserTypes, setAllUserTypes] = useState<ISelectWithSearchItemType[]>([]);
  const [customFields, setCustomFields] = useState<IUpdatedCustomField[]>([]);
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({ name: '', lastName: '', emailUserInvited: '' });
  const [groupDropdownListOptions, setGroupsDropdownItems] = useState<IDropDownListOption[]>();
  const [hasCustomFieldsReturned, setHasCustomFieldsReturned] = useState<boolean>(false);
  const [invitation, setInvitation] = useState<IDBInvitation | undefined>(selectedInvitation);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isGroupSearchContentVisible, setSearchIsGroupContentVisible] = useState(false);
  const [isRequiresActivation, setIsRequiresActivation] = useState<boolean>(true);
  const [isSocialAccessActive, setIsSocialAccessActive] = useState<boolean>(false);
  const [isTeamAdmin, setIsTeamAdmin] = useState<boolean>(!hasGroups);
  const [isUserTypeSearchContentVisible, setIsUserTypeSearchContentVisible] = useState(false);
  const [projectId, setProjectId] = useState<string>('');
  const [userPassword, setUserPassword] = useState<string>('');
  const [userTypePermission, setUserTypePermission] = useState<DBMembershipPermissionType>('READ');

  const { customMessage, groupId, id: invitationId, userType, phoneNumber } = invitation ?? ({} as IDBInvitation);
  const { id: userTypeId, name: userTypeName } = userType ?? {};

  const handleValidationError = ({ error, invalid }: ControllerFieldState): string => {
    if (!(error && invalid)) {
      return '';
    }

    const { message = '' } = error;

    return message;
  };

  const { control, handleSubmit, reset } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().label('First name').required(),
        lastName: yup.string().label('Last name').required(),
        emailUserInvited: yup.string().label('Email').email('Please enter a valid email').required(),
      }),
    ),
  });

  const { handleCreateUser, isLoading: isCreateUserLoading = false } = useCreateUser({
    onCompleted: ({ user }) => {
      if (isEmpty(user)) {
        reset();
        onUpdated?.();
        onClose();
        return;
      }

      handleToastSuccess({
        message: 'User created',
      });

      reset();
      onUpdated?.()
      onClose();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const [createProjectInvitation, { loading: isCreateProjectInvitationLoading = false }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        onUpdated?.();

        handleToastSuccess({
          message: 'Invitation sent',
        });

        reset();
        onClose();
      },
      onError: (error) => {
        handleToastError({
          shouldUseFriendlyErrorMessage: true,
          error,
        });
      },
    },
    mutation: CREATE_PROJECT_INVITATION,
    product: getProduct(),
  });

  const { handleCreateGroupInvitation, isLoading: isCreateGroupInvitationLoading = false } = useCreateGroupInvitation({
    onCompleted: ({ invitation: createdInvitation }) => {
      if (isEmpty(createdInvitation)) {
        reset();
        onClose();
        return;
      }

      onUpdated?.();

      handleToastSuccess({
        message: 'Invitation sent',
      });

      reset();
      onClose();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleResendInvitation, isLoading: isResendInvitationLoading = false } = useResendInvitation({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Invitation sent',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    product: getProduct(),
  });

  const { handleDeleteInvitation, isLoading: isDeleteInvitationLoading = false } = useDeleteInvitation({
    onCompleted: ({ isCompleted }) => {
      if (!isCompleted) {
        return;
      }

      if (onDeleted) {
        onDeleted(invitationId);
      }

      onClose();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetCustomFields, isLoading: isGetCustomFieldsLoading = false } = useGetCustomFields({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const updatedCustomFields: IUpdatedCustomField[] = data.customFields.map((customField) => {
        const { id: customFieldId, __typename, name: customFieldName, responses = [] } = customField as unknown as ICustomField;

        const value = responses?.length ? responses[0].text ?? responses[0].value ?? responses[0].dateTime ?? responses[0].number : '';

        return {
          customFieldId,
          customFieldName,
          text: value,
          __typename,
        };
      });
      setCustomFields(updatedCustomFields);
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const { handleGetCustomFieldInvitationAnswers, isLoading: isGetCustomFieldInvitationAnswersLoading = false } =
    useGetCustomFieldInvitationAnswers({
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const { customFields: customFieldAnswers }: { customFields: IInvitationCustomField[] } = data;

        const updatedCustomFields: IUpdatedCustomField[] = customFields.map((customField) => {
          const invitationAnswer = customFieldAnswers.find((answer) => answer.customFieldId === customField.customFieldId);

          const value = invitationAnswer ? invitationAnswer.text ?? invitationAnswer.dateTime ?? invitationAnswer.number : '';
          return {
            ...customField,
            text: value,
          } as IUpdatedCustomField;
        });

        setCustomFields(updatedCustomFields);
        setHasCustomFieldsReturned(true);
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    });

  const { handleSearchUserTypes, isLoading: isSearchUserTypesLoading = false } = useSearchUserTypes({
    onCompleted: ({ userTypes }) => {
      const userTypesDropdownList: ISelectWithSearchItemType[] = [...(userTypes as IBulkInvitesUserType[])].map(({ id, name }) => ({
        id,
        label: name,
      }));

      setAllUserTypes(userTypesDropdownList);
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const { handleSearchGroups, isLoading: isSearchGroupsLoading = false } = useSearchGroups({
    onCompleted: ({ groups }) => {
      const groupsSelectSearchList = groups.map(
        ({ id, name }): IDropDownListOption => ({
          id: String(id),
          label: name,
          value: String(id),
        }),
      );

      setGroupsDropdownItems(
        hasAllGroupsOption
          ? [
              {
                id: '0',
                label: 'All groups',
                value: '0',
              },
              ...groupsSelectSearchList,
            ]
          : groupsSelectSearchList,
      );
    },
    onError: (error) => {
      handleToastError({
        error,
        hasCloseButton: true,
      });
    },
  });

  const handleCreateInvitation = ({ name, lastName, emailUserInvited }: FormFieldProps) => {
    if (!isTeamAdmin && !invitation) {
      return;
    }

    const customFieldResponses = customFields.map(({ text, customFieldId }) => {
      return {
        customFieldId,
        value: String(text),
      };
    });

    if (!isRequiresActivation) {
      void handleCreateUser({
        email: emailUserInvited,
        firstname: name,
        groupId: Number(groupId),
        lastname: lastName ?? '',
        password: userPassword,
        phoneNumber,
        projectId: String(projectId),
        services: isSocialAccessActive ? socialServices : defaultServices,
        userTypeId,
        customFieldResponses,
      });
    } else {
      if (!isTeamAdmin) {
        void handleCreateGroupInvitation({
          customMessage,
          firstName: name,
          customFields: customFieldResponses,
          groupId: Number(groupId),
          invitedUserEmail: emailUserInvited,
          lastName,
          permission: 'READ',
          services: isSocialAccessActive ? socialServices : defaultServices,
          userTypeId,
          phoneNumber,
        });
      } else {
        void createProjectInvitation({
          variables: {
            invitation: {
              customMessage,
              firstName: name,
              customFields: customFieldResponses,
              invitedEmail: emailUserInvited,
              phoneNumber,
              lastName,
              permission: userTypePermission,
              projectId: String(projectId),
            },
          },
        });
      }
    }
  };

  const handleUserPasswordUpdate = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setUserPassword(value);
  };

  const handleRequiresActivation = () => {
    setIsRequiresActivation(!isRequiresActivation);
  };

  const handleSocialAccessActive = () => {
    setIsSocialAccessActive(!isSocialAccessActive);
  };

  const handleUpdateCustomFieldDate = (date: Date | null, customFieldId: number, customFieldName: string) => {
    const updatedFields = customFields.map((customField) => {
      const { customFieldId: currentCustomFieldId } = customField;

      if (customFieldId === currentCustomFieldId) {
        return {
          customFieldId,
          customFieldName,
          text: date?.toISOString() ?? '',
          __typename: customField.__typename,
        };
      }

      return customField;
    });

    setCustomFields(updatedFields);
  };

  const handleUpdateCustomFieldBoolean = ({ target }: ChangeEvent<HTMLInputElement>, customFieldId: number, customFieldName: string) => {
    const { checked: isChecked } = target;
    const updatedFields = customFields.map((customField) => {
      const { customFieldId: currentCustomFieldId } = customField;

      if (customFieldId === currentCustomFieldId) {
        return {
          customFieldId,
          customFieldName,
          text: String(isChecked).charAt(0).toUpperCase() + String(isChecked).slice(1),
          __typename: customField.__typename,
        };
      }

      return customField;
    });

    setCustomFields(updatedFields);
  };

  const handleUpdateCustomField = (
    { target: { value } }: ChangeEvent<HTMLInputElement>,
    customFieldId: number,
    customFieldName: string,
  ) => {
    const updatedFields = customFields.map((customField) => {
      const { customFieldId: currentCustomFieldId } = customField;

      if (customFieldId === currentCustomFieldId) {
        return {
          customFieldId,
          customFieldName,
          text: value.toString(),
          __typename: customField.__typename,
        };
      }

      return customField;
    });

    setCustomFields(updatedFields);
  };

  const handleUpdateInput = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLOutputElement | HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setInvitation(
      (currentState) =>
        ({
          ...currentState,
          [name]: value,
        } as IDBInvitation),
    );
  };

  const handlePermissionsChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setUserTypePermission(value as DBMembershipPermissionType);
  };

  useEffect(() => {
    if (!projectId) {
      return;
    }

    void handleSearchUserTypes({
      projectId: String(projectId),
    });

    void handleGetCustomFields({
      projectId: String(projectId),
      scope: 'USER',
    });

    void handleSearchGroups({
      projectId: String(projectId),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (!(selectedInvitation && projectId)) return;

    if (customFields.length === 0 || hasCustomFieldsReturned) return;

    void handleGetCustomFieldInvitationAnswers({
      id: selectedInvitation.id,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInvitation, customFields]);

  useEffect(() => {
    setDefaultValues({
      name: selectedInvitation?.firstName ?? '',
      lastName: selectedInvitation?.lastName ?? '',
      emailUserInvited: selectedInvitation?.emailUserInvited ?? '',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInvitation]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading =
    isCreateGroupInvitationLoading ||
    isCreateUserLoading ||
    isDeleteInvitationLoading ||
    isResendInvitationLoading ||
    isSearchUserTypesLoading ||
    isGetCustomFieldsLoading ||
    isCreateProjectInvitationLoading ||
    isSearchGroupsLoading ||
    isGetCustomFieldInvitationAnswersLoading;

  return (
    <SidebarContainer>
      <Spinner isLoading={isLoading} />

      <ControlledForm
        callBack={(item: FormFieldProps) => {
          const { name, lastName, emailUserInvited } = item;
          handleCreateInvitation({ name, lastName, emailUserInvited });
        }}
        handleSubmit={handleSubmit}
      >
        <Spinner isLoading={isLoading} />
        <Controller
          control={control as Control<FormFieldProps>}
          name="name"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={handleValidationError(fieldState)}
              id="id_first_name"
              isDisabled={Boolean(invitationId)}
              labelText="First name"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
        <Controller
          control={control as Control<FormFieldProps>}
          name="lastName"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={handleValidationError(fieldState)}
              id="id_last_name"
              isDisabled={Boolean(invitationId)}
              labelText="Last name"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />

        <Controller
          control={control as Control<FormFieldProps>}
          name="emailUserInvited"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={handleValidationError(fieldState)}
              id="id_email_user_invited"
              isDisabled={Boolean(invitationId)}
              labelText="Email"
              type="email"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />

        <Input
          id="phoneNumber"
          isDisabled={Boolean(invitationId)}
          labelText="Phone number"
          name="phoneNumber"
          type="text"
          value={phoneNumber}
          isLabelSideBySide
          onChange={handleUpdateInput}
        />

        <section>
          {Boolean(!invitationId) && hasGroups && !isProduct && (
            <Spacing>
              <ToggleSwitch
                id="id_team_admin_access"
                isChecked={isTeamAdmin}
                labelText="Team admin access"
                tooltipPosition="start"
                tooltipText="This will enable the selected user to access and manage features within the CMS. Only select this if the user is on your project team"
                isLabelSideBySide
                onChange={() => {
                  if (!isTeamAdmin) {
                    setIsRequiresActivation(true);
                    setInvitation(
                      (currentState) =>
                        ({
                          ...currentState,
                          groupId: undefined,
                          userType: undefined,
                        } as IDBInvitation),
                    );
                  } else {
                    setIsRequiresActivation(false);
                    setUserTypePermission('READ');
                  }
                  setIsTeamAdmin(!isTeamAdmin);
                }}
              />
            </Spacing>
          )}

          {!isTeamAdmin && (
            <>
              <SelectWithSearch
                additionalClassNames="c-select-with-search__users-table"
                buttonText={groupDropdownListOptions?.find(({ id }) => Number(id) === Number(groupId))?.label ?? 'All groups'}
                id="groups"
                isDisplaySearchContent={isGroupSearchContentVisible}
                isDropdownDisabled={Boolean(selectedInvitation)}
                isLabelHidden={false}
                labelText="Default Group"
                searchList={groupDropdownListOptions ?? []}
                tooltipPosition="start"
                tooltipText="This user can belong to one or many groups, please select their default group now. Groups help filter and define different functionalities for users within your project"
                isAvatarVisible
                isLabelSideBySide
                isRequired
                onDisplaySearchContent={() => setSearchIsGroupContentVisible(!isGroupSearchContentVisible)}
                onSearchItemClick={(id: string | number) =>
                  setInvitation(
                    (currentState) =>
                      ({
                        ...currentState,
                        groupId: Number(id),
                      } as IDBInvitation),
                  )
                }
              />

              <SelectWithSearch
                additionalClassNames="c-select-with-search__users-table"
                buttonText={userTypeName ?? 'All user roles'}
                id="userTypes"
                isDisplaySearchContent={isUserTypeSearchContentVisible}
                isDropdownDisabled={Boolean(selectedInvitation)}
                isLabelHidden={false}
                labelText="Default role"
                searchList={allUserTypes}
                tooltipPosition="start"
                tooltipText="This user needs to be assigned to role, this helps assign permissions and access to specific features in your application"
                isAvatarVisible
                isLabelSideBySide
                isRequired
                onDisplaySearchContent={() => setIsUserTypeSearchContentVisible(!isUserTypeSearchContentVisible)}
                onSearchItemClick={(id: string | number) =>
                  setInvitation(
                    (currentState) =>
                      ({
                        ...currentState,
                        userType: {
                          id: Number(id),
                        },
                      } as IDBInvitation),
                  )
                }
              />
            </>
          )}

          {customFields.map((customField) => {
            const { customFieldId, customFieldName, text, __typename } = customField;
            return (
              <section key={`custom-field-${customFieldId}`}>
                {__typename === 'CustomFieldBooleanGraphType' && (
                  <ToggleSwitch
                    id={`custom-field-${customFieldId}`}
                    isChecked={String(text).toLowerCase() === 'true'}
                    isDisabled={Boolean(invitationId)}
                    labelText={customFieldName}
                    isLabelSideBySide
                    onChange={(event) => handleUpdateCustomFieldBoolean(event, customFieldId, customFieldName)}
                  />
                )}
                {__typename === 'CustomFieldDateGraphType' && (
                  <SingleDatePicker 
                    config={{
                      maxDate: new Date('01-01-2050'),
                      }} 
                      dateInputProps={{
                        labelText: customFieldName,
                        id: `custom-field-${customFieldId}`,
                        type: 'text',
                        name: 'custom-field-datepicker',
                        isLabelSideBySide: true,
                        
                      }}
                      isDisabled={Boolean(invitationId)}
                      selectedDates={text ? [new Date(text)]: undefined} 
                      onSelected={(dates: Date[]) => handleUpdateCustomFieldDate(dates[0], customFieldId, customFieldName)} 
                    />
                )}
                {__typename === 'CustomFieldTextGraphType' && (
                  <Input
                    id={`custom-field-text-${customFieldName}`}
                    isDisabled={Boolean(invitationId)}
                    labelText={customFieldName}
                    name={customFieldName}
                    type="text"
                    value={text}
                    isLabelSideBySide
                    onChange={(event) => handleUpdateCustomField(event, customFieldId, customFieldName)}
                  />
                )}
                {__typename === 'CustomFieldNumberGraphType' && (
                  <Input
                    id={`custom-field-number-${customFieldName}`}
                    isDisabled={Boolean(invitationId)}
                    labelText={customFieldName}
                    name={customFieldName}
                    type="number"
                    value={text}
                    isLabelSideBySide
                    onChange={(event) => handleUpdateCustomField(event, customFieldId, customFieldName)}
                  />
                )}
              </section>
            );
          })}

          {Boolean(!invitationId) && services?.includes('BONOBO') && !isTeamAdmin && hasGroups && (
            <Spacing size="large">
              <ToggleSwitch
                id="socialAccess"
                isChecked={isSocialAccessActive}
                labelText="Forum | Social access"
                isLabelSideBySide
                onChange={handleSocialAccessActive}
              />
            </Spacing>
          )}
        </section>

        {Boolean(!invitationId) && !isTeamAdmin && (
          <Spacing size="large">
            <ToggleSwitch
              id="requiresActivation"
              isChecked={isRequiresActivation}
              labelText="Requires activation"
              isLabelSideBySide
              onChange={handleRequiresActivation}
            />
          </Spacing>
        )}

        {Boolean(!invitationId) && isTeamAdmin && (
          <Spacing size="large">
            <RadioButtonGroup
            items={PERMISSIONS_ITEMS.filter((r) => !r.product || r.product === getProduct())}
              name="permissions"
              selectedValue={userTypePermission}
              title="Permissions"
              isLabelSideBySide
              onChange={handlePermissionsChange}
            />
          </Spacing>
        )}

        {isRequiresActivation && (
          <Spacing size="large">
            <Textarea
              id="custom-message"
              isDisabled={Boolean(invitationId)}
              labelText="Invite message"
              name="customMessage"
              value={customMessage ?? ''}
              isLabelSideBySide
              onChange={handleUpdateInput}
            />
          </Spacing>
        )}

        {!isRequiresActivation && (
          <Spacing size="large">
            <PasswordInput id="password"labelText="Password" name="confirmPassword" isLabelSideBySide onChange={handleUserPasswordUpdate} />
          </Spacing>
        )}

        {Boolean(invitationId) && (
          <SidebarButtons
            buttonSize="xs"
            submitButtonText="Resend"
            onCancel={onClose}
            onDelete={() => setIsDeleteDialogOpen(true)}
            onSave={() => {
              void handleResendInvitation({
                invitationId,
              });
            }}
            onSaveButtonType="button"
          />
        )}

        <Dialog
          isOpen={isDeleteDialogOpen}
          title="Delete invitation?"
          isNarrow
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={() => {
            void handleDeleteInvitation({
              invitationId,
            });
          }}
        />

        {Boolean(!invitationId) && (
          <SidebarButtons
            buttonSize="xs"
            isSaveButtonDisabled={Boolean(!(groupId && userTypeId)) && !isTeamAdmin}
            submitButtonText="Invite"
            onCancel={onClose}
            onSaveButtonType="submit"
          />
        )}
      </ControlledForm>
    </SidebarContainer>
  );
};

export { InvitationsGeneralTab };
